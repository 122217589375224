<!--
 * @Description: 需求列表页面
 * @Author: zhang zhen
 * @Date: 2023-02-14 15:35:23
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-05-14 16:54:06
 * @FilePath: /page-sass/src/views/requirement/requirementList/modules/forUserAndPurchase.vue
-->
<template>
  <div class="infoCard">
    <div class="card-info-box">
      <div class="searchInfo">
        <a-input v-model="queryParams.keywords" placeholder="请输入需求单号、需求名称或其他关键词"
          style="width: 480px; height: 38px; line-height: 38px;" />
        <div class="option-area">
          <a-button @click="resetData" style="width: 81px;">重置</a-button>
          <a-button type="primary" @click="handleSearch" style="width: 81px;">查询</a-button>
        </div>
      </div>
      <a-form-model :label-col="labelCol" :wrapper-col="wrapperCol" labelAlign="left" style="margin-top: 8px;"
        :colon="false">
        <a-form-model-item label="需求类型">
          <checkboxSearch v-model="queryParams.purchaseType" dictCode="1043" />
        </a-form-model-item>
        <a-form-model-item label="包装材料">
          <checkboxSearch v-model="queryParams.wrapTypes" dictCode="1507" />
        </a-form-model-item>
        <a-form-model-item label="需求状态">
          <checkboxSearch v-model="queryParams.status" :options="mainOptions" :needLoad="false" />
        </a-form-model-item>
        <a-form-model-item label="截止报价时间">
            <radioGroup v-model="validityDateScoped" :options="otherOptions" :needLoad="false"
              @change="handleChangeRadio($event, 'validityDate')">
              <a-radio :value="-1">
                <a-range-picker v-model="validityDateRange" value-format="YYYY-MM-DD HH:mm:ss" separator="⇀" showTime
                  @change="handleChangeDate($event, 'validityDateScoped')" style="width: 310px;height: 26px;">
                  <span class="pickerDict">
                    <span v-if="validityDateRange.length">{{ validityDateRange[0] }} ~ {{ validityDateRange[1] }}</span>
                    <span class="placeholder" v-else>请选择</span>
                    <a-icon type="caret-down" style="margin-left: 8px;color:#999"></a-icon>
                  </span>
                </a-range-picker>
              </a-radio>
            </radioGroup>
          </a-form-model-item>
      </a-form-model>
    </div>
    <div class="card-info-box">
      <a-table :columns="columns" :loading="loading" :data-source="dataSource" :pagination="false"
        style="margin-bottom: 16px;" :scroll="{ x: 'max-content' }">
        <span class="row_title" slot="purchaseNo" slot-scope="text, record">
          <span class="orderName">
            {{ text }}
          </span>
          <img src="/new.png" alt="" class="newIcon" v-if="record.newFlag">
        </span>
        <template slot="wrapTypesDictName" slot-scope="text, record">
          <a-tooltip placement="top">
            <template slot="title">
              <span>{{ text }}</span>
            </template>
            <span>{{ text | ellipsis(6) }}</span>
          </a-tooltip>
        </template>
        <template slot="quotationDeadline" slot-scope="text, record">
          <div>
            {{ text && text.substring(0, 10) || ''}}
          </div>
        </template>
        <template slot="status" slot-scope="text, record">
          <span v-if="text == 0">已关闭</span>
          <span v-if="text == 1">已发布</span>
          <span v-if="text == 2">已完成</span>
          <span v-if="text == 3">待发布</span>
          <span v-if="text == 4">待审核</span>
          <span v-if="text == 5">已拒绝</span>
          <span v-if="text == -1">已删除</span>
        </template>
        <!-- quotesCount -->
        <template slot="participantCount" slot-scope="text, record">{{ record.quotesCount || 0 }}/{{ text }}</template>
        <template slot="operation" slot-scope="text, record">
          <a-badge :dot="record.joinFlag">
            <a @click="handleSwitchTab(record)">详情</a>
          </a-badge>
          <template v-if="record.status == 3 || record.status == 5">
            <a-divider type="vertical" />
            <a @click="handleEdit(record)">编辑</a>
          </template>
          <template v-if="record.status == 3 || record.status == 0">
            <a-divider type="vertical" />
            <a @click="handleEmitDelete(record)">删除</a>
          </template>
        </template>
      </a-table>
      <ZPagination :total="pageNation.total" :current="pageNation.pageNum" :pageSize="pageNation.pageSize"
        @handlePageChange="handleChangePage" />
    </div>
    <!-- 翻单 -->
    <a-modal title="温馨提示" :visible="visible" centered @ok="handleDeleteItem" @cancel="visible = false">
      <div class="info-card">
        <img src="/warning.png" alt="" />
        <span>是否删除当前需求?</span>
      </div>
    </a-modal>
  </div>
</template>

<script>
import ZPagination from '@/components/plugins/ZPagination.vue'
import checkboxSearch from '@/components/plugins/checkboxSearch.vue'
import { postAction } from '@/api/manage'
import { mapGetters, mapActions } from 'vuex'
import EmptyArea from '@/components/plugins/EmptyArea.vue'
import { getMonthDateRange } from '@/util/utils'
import radioGroup from '@/components/plugins/radioGroup.vue'

export default {
  name: 'forUserAndPurchase',
  components: {
    ZPagination,
    EmptyArea,
    checkboxSearch,
    radioGroup
  },
  data() {
    return {
      loading: false,
      queryParams: {},
      businessType: '0',
      targetItem: {},
      visible: false,
      orderStatus: '',
      validityDateScoped: '',
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
      columns: [
        {
          title: '需求编号',
          dataIndex: 'purchaseNo',
          key: 'purchaseNo',
          width: 234,
          scopedSlots: { customRender: 'purchaseNo' },
        },
        {
          title: '需求名称',
          dataIndex: 'purchaseTitle',
          key: 'purchaseTitle',
          width: 200
        },
        {
          title: '需求类型',
          dataIndex: 'purchaseTypeDictName',
          key: 'purchaseTypeDictName',
          width: 120
        },
        {
          title: '包装材料',
          dataIndex: 'wrapTypesDictName',
          key: 'wrapTypesDictName',
          scopedSlots: { customRender: 'wrapTypesDictName' },
          width: 160
        },
        {
          title: '需求状态',
          dataIndex: 'status',
          key: 'status',
          align: 'center',
          scopedSlots: { customRender: 'status' },
          width: 109
        },
        {
          title: '供应商已参与人数',
          dataIndex: 'participantCount',
          key: 'participantCount',
          align: 'center',
          scopedSlots: { customRender: 'participantCount' },
          width: 182
        },
        {
          title: '截止报价时间',
          dataIndex: 'quotationDeadline',
          key: 'quotationDeadline',
          scopedSlots: { customRender: 'quotationDeadline' },
          width: 130
        },
        {
          title: '提交时间',
          dataIndex: 'createTime',
          key: 'createTime',
          width: 180
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          width: 151,
          fixed: 'right'
        }
      ],
      pageNation: {
        pageNum: 1,
        pageSize: 20,
        total: 0
      },
      validityDateRange: [],
      dataSource: [],
      //  待发布 待审核 已发布 已完成 已拒绝 已关闭
      mainOptions: [
        {
          dictKey: '3',
          dictValue: '待发布'
        },
        {
          dictKey: '4',
          dictValue: '待审核'
        },
        {
          dictKey: '1',
          dictValue: '已发布'
        },
        {
          dictKey: '2',
          dictValue: '已完成'
        },
        {
          dictKey: '5',
          dictValue: '已拒绝'
        },
        {
          dictKey: '0',
          dictValue: '已关闭'
        }
      ],
      otherOptions: [
        {
          dictKey: '0',
          dictValue: '本月'
        },
        {
          dictKey: '1',
          dictValue: '下月'
        },
        {
          dictKey: '2',
          dictValue: '两月后'
        },
      ],
    }
  },
  created() {
    this.handleSearch()
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    ...mapActions(['changeInfoCount']),
    handleCleanCount() {
      this.changeInfoCount({
        requirementCount: 0
      })
    },
    handleSwitchTab(item) {
      const { purchaseId, purchaseTitle, purchaseNo, businesStatus, status, quotesCount } = item
      this.$router.push(
        `/requirement/ForUserAndPurchaseDetails?purchaseId=${purchaseId}&purchaseTitle=${purchaseTitle}&purchaseNo=${purchaseNo}&activeTab=BasicInfo&formType=${businesStatus == '0' ? 'product' : businesStatus == '1' ? 'package' : 'plan'}&status=${status}&hasJoin=${quotesCount && quotesCount > 0 ? '1' : '0'}`
      )
    },
    handleChangeDate(e, key) {
      if (e && e.length) {
        this[key] = -1
      }
    },
    handleChangeRadio(e, key) {
      if (e != '-1') {
        this[`${key}Range`] = [];
      }
    },
    handleLoadData() {
      const { pageNum, pageSize } = this.pageNation
      const { purchaseType, status, keywords, wrapTypes } = this.queryParams;
      let purchaseTypesList = [], statusList = [], wrapTypesList = [], params = [];
      if (purchaseType) {
        purchaseTypesList = purchaseType.split(',');
      }
      if (wrapTypes) {
        wrapTypesList = wrapTypes.split(',');
      }
      if (status) {
        statusList = status.split(',');
      }
      console.log('this.validityDateScoped', this.validityDateScoped)
      if (this.validityDateScoped != '' && this.validityDateScoped != -1) {
        const [validityDateStart, validityDateEnd] = getMonthDateRange(this.validityDateScoped)
        params = { ...params, validityDateStart, validityDateEnd }
      } else if (this.validityDateScoped == -1 && this.validityDateRange.length) {
        const [validityDateStart, validityDateEnd] = this.validityDateRange;
        params = { ...params, validityDateStart, validityDateEnd }
      }
      this.loading = true
      postAction('/purchase/pool/publish/query', {
        businessId: this.setUserInfo().businessId,
        ...params,
        pageNum,
        pagesSize: pageSize,
        keywords: keywords || '',
        wrapTypes: wrapTypesList,
        purchaseType: purchaseTypesList,
        status: statusList,
        businessType: this.businessType,
        userType: ''
      }).then(res => {
        const { success, data } = res
        this.loading = false
        if (success) {
          const { total, list } = data
          this.pageNation.total = total
          this.dataSource = list
        }
      }).catch(err => {
        this.loading = false
      })
    },
    handleChangePage({ pageNum, pageSize }) {
      this.pageNation.pageNum = pageNum
      this.pageNation.pageSize = pageSize
      this.handleLoadData()
    },
    handleChangeCategory() {
      this.handleSearch()
    },
    handleEdit({ purchaseId }) {
      this.$router.push({
        path: '/PublishingRequirements',
        query: {
          formType: 'product',
          stepKey: 'CompleteInformation',
          purchaseId
        }
      })
    },
    resetData() {
      this.queryParams = {}
      this.validityDateScoped = ''
      this.validityDateRange = []
      this.handleSearch()
    },
    handleSearch() {
      this.pageNation = {
        pageNum: 1,
        pageSize: 20,
        total: 0
      }
      this.handleLoadData()
    },
    handleEmitDelete(item) {
      const { status, purchaseId } = item;
      this.targetItem = { status, purchaseId }
      this.visible = true;
    },
    handleDeleteItem() {
      // 接口：
      postAction('/purchasesBasic/deletePurchase', {
        ...this.targetItem
      }).then(res => {
        const { success, result, message } = res;
        if (success) {
          this.handleSearch()
          this.visible = false;

        } else {
          this.$message.error(message)
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.statusBox {
  margin-bottom: 15px;
}

.ant-card {
  border-radius: 8px;
  background: #fffff8;

  ::v-deep .ant-tabs-card-bar {
    margin-bottom: 0;
  }

  ::v-deep .ant-tabs-tabpane-active {
    border: 1px solid #e4e4e4;
    border-top: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 15px 15px 0;
    background: #fff;
  }

  .tableCardTitle {
    text-align: center;
    width: 100%;
    padding: 8px 15px;
    background: #f7f7f7;
    margin-bottom: 15px;
    .flexLayout();
    font-size: 13px;
    color: #66766d;

    div.options {
      width: 186px;
    }

    div.left-title {
      width: 300px;
      text-align: left;
    }

    div.orderNum {
      width: 140px;
    }

    .status {
      width: 120px;
    }
  }
}

.searchInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  .option-area {
    .ant-btn {
      width: 97px;
      height: 38px;

      &+.ant-btn {
        margin-left: 8px;
      }
    }
  }
}

::v-deep.ant-tabs {
  color: rgba(0, 0, 0, 0.85);
}

::v-deep.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:not(.ant-tabs-tab-active) {
  background-color: #f7f8fa;
}

::v-deep .ant-tabs-tab-active {
  font-weight: 500;
}

.ant-divider {
  background-color: #FF6E2D;
}

::v-deep .ant-table {
  color: rgba(0, 0, 0, 0.85);
}

::v-deep .ant-table-column-title {
  font-size: 16px;
}

::v-deep .ant-table-thead {
  tr {
    background: #efefef;

    th {
      border: none;
    }
  }
}

::v-deep .ant-table-tbody {
  tr {
    &:not(:last-of-type)>td {
      border: none;
    }

    &>td {
      border-bottom: 1px solid #EFEFEF;
    }
  }
}

::v-deep .ant-table-thead>tr>th {
  background: #efefef;
  border-bottom: none;
  font-weight: 500;

}

::v-deep .ant-form-item-control {
  line-height: 30px;
}

::v-deep .ant-form-item-label {
  line-height: 30px;
}

@import '~@/styles/list.less';

.info-card {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;

  img {
    width: 21px;
    height: 21px;
  }

  span {
    margin-left: 10px;
  }
}
// ::v-deep .ant-form-item-label {
//   width: 94px!important;
// }

.pickerDict {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: max-content;
  min-width: 140px;
  height: 26px;
  color: #131212;
  cursor: pointer;
  padding: 0 6px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  .placeholder {
    color: #999;
  }
}
.row_title {
  display: flex;
  align-items: center;
}
img.newIcon {
  width: 16px;
  margin-left: 6px;
}

.card-info-box {
  ::v-deep .ant-badge-dot {
    right: -6px;
  }  
}
</style>
